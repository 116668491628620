import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";

const Directories = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div
        className="client-area client-area-pd bg-overlay pd-top-118"
        style={{ backgroundImage: `url(${rpdata?.gallery?.[2]})` }}
      >
        <div className="container">
          <div className="row justify-content-center text-lg-left text-center">
            <div className="col-lg-9">
              <div className="section-title style-white mb-0">
                <h6>{rpdata?.dbSlogan?.[6].slogan}</h6>
                <h2>{rpdata?.dbSlogan?.[3].slogan}</h2>
              </div>
            </div>
            <div className="col-lg-3 align-self-end text-lg-right mt-4 mt-lg-0 go-top">
              <Link className="btn btn-base" to="/contact">
                FREE ESTIMATE
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
      <div className="contentGMB pd-top-100">
          <a href={rpdata?.gmb?.link} target="_blank" >
            <img
              src={rpdata?.gmb?.img}
              alt='gmb'
            />
          </a>
      </div>
        <h2 className="pd-top-10 text-center">Find Us</h2>
        <div className="contentDirectorios pd-top-20">
          {
            rpdata?.dbSocialMedia?.directorios.length > 1 ?
              rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                return (
                  <div

                    key={index}
                  >
                    <a href={item.url} target="_blank">
                      <img src={item.image} alt='images' />
                    </a>
                  </div>
                );
              })
              : null
          }
          {
            rpdata?.dbSocialMedia?.redes.length > 1 ?
              rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <div

                    key={index}
                  >
                    <a href={item.url} target="_blank">
                      <img src={item.image} alt='images' />
                    </a>
                  </div>
                );
              })
              : null
          }
        </div>
      </div>
    </div>
  );
};

export default Directories;
